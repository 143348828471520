import { render, staticRenderFns } from "./record.province.vue?vue&type=template&id=4489e3fe"
import script from "./record.province.vue?vue&type=script&lang=js"
export * from "./record.province.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports