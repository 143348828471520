<template>
  <div>
    <!-- <jc-row :gutter="10">
      <jc-col :span="8"> -->
    <jc-select
      v-model="region.province"
      placeholder="请选择"
      @change="provinceChange"
      @visible-change="deleteLock"
      :disabled="disabled"
      style="width:110px;margin-right:5px"
    >
      <jc-option
        v-for="itemOption in provinceOptions"
        :key="itemOption.code"
        :label="itemOption.name"
        :value="itemOption.code"
        :disabled="FILTERED_PROVINCES.includes(itemOption.name)"
      >
      </jc-option>
    </jc-select>
    <!-- </jc-col> -->
    <!-- <jc-col :span="8"> -->
    <jc-select
      v-model="region.city"
      placeholder="请选择"
      @change="cityChange"
      @visible-change="deleteLock"
      v-loading="cityLoading"
      :disabled="disabled && recordType !== 4 && recordType !== 14"
      style="width:110px;margin-right:5px"
    >
      <jc-option
        v-for="itemOption in cityOptions"
        :key="itemOption.code"
        :label="itemOption.name"
        :value="itemOption.code"
      >
      </jc-option>
    </jc-select>
    <!-- </jc-col> -->
    <!-- <jc-col :span="8"> -->
    <jc-select
      v-model="region.county"
      placeholder="请选择"
      v-loading="countyLoading"
      :disabled="disabled && recordType !== 4 && recordType !== 14"
      style="width:110px"
    >
      <jc-option
        v-for="itemOption in countyOptions"
        :key="itemOption.code"
        :label="itemOption.name"
        :value="itemOption.code"
        :disabled="FILTERED_COUNTIES.includes(itemOption.name)"
      >
      </jc-option>
    </jc-select>
    <!-- </jc-col>
    </jc-row> -->
  </div>
</template>
<script>
import { getDictionary } from '@/service/record-api'
import profileMixins from '@/mixins/profile.mixins.js'
import { FILTERED_PROVINCES, FILTERED_COUNTIES } from '@/utils/enum.js'
export default {
  mixins: [profileMixins],
  data() {
    return {
      recordNo: this.$route.params.recordNo || '', // 主体变更 recordNo = 4 可以编辑市县
      countyLoading: false,
      cityLoading: false,
      cityOptions: [],
      countyOptions: [],
      region: {
        province: '',
        city: '',
        county: ''
      },
      locked: this.display,
      FILTERED_PROVINCES,
      FILTERED_COUNTIES
    }
  },
  props: {
    value: {
      // 省市县code
      type: Object,
      require: true
    },
    provinces: {
      // 省列表
      required: true,
      type: Array
    },
    // 是否禁用
    disabled: Boolean,
    // 是否回显
    display: Boolean
  },
  watch: {
    value(val) {
      this.region = val
    },
    region: {
      deep: true,
      handler(val) {
        console.log('province emit')
        this.$emit('input', val)
      }
    },
    display(val) {
      this.locked = val
    }
  },
  created() {
    setTimeout(() => {
      this.initProvince()
    }, 1000)
  },
  methods: {
    // 删除锁定， 回显时候的锁定
    deleteLock() {
      if (this.locked) {
        this.locked = false
      }
    },
    // 更改省
    async provinceChange() {
      this.cityLoading = true
      if (!this.locked) {
        this.region = {
          ...this.region,
          city: '',
          county: ''
        }
      }
      this.initCities()
    },
    // 更改市
    async cityChange() {
      if (!this.locked) {
        this.region.county = ''
      }
      this.initCountry()
    },
    // 初始化数据
    initProvince() {
      this.region = this.value
      if (!this.locked) {
        this.cityOptions = []
        this.countyOptions = []
      }
    },
    // 省更改后获取市的下拉列表
    async initCities() {
      const { province } = this.region

      this.countyOptions = []
      console.log(province)
      if (!province) {
        this.cityOptions = []
        return
      }
      console.log('getCityOption')
      try {
        this.cityOptions = await this.getData({ code: province, type: 'city' })
      } catch (err) {
        console.error(err)
      } finally {
        this.cityLoading = false
      }
    },
    // 市更改后获取县的下拉列表
    async initCountry() {
      const { city } = this.region
      console.log(city)
      if (!city) {
        this.countyOptions = []
        return
      }
      this.countyLoading = true
      console.log('getCountryOption')
      try {
        this.countyOptions = await this.getData({ code: city, type: 'county' })
      } catch (err) {
        console.error(err)
      } finally {
        this.countyLoading = false
      }
    },
    // 查询字典api
    async getData(params) {
      const { data } = await getDictionary(params)
      return data || []
    }
  },
  mounted() {},
  filters: {}
}
</script>
